import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
const ItemsList = dynamic(() => import('../common/items_list'))
const ProductItem = dynamic(() => import('../common/product_item'))
import s from './goods_group.module.scss'

const GoodsGroup = ({ title, showAll = '', items, allGoodsText = 'Усі товари', segmentation = false }) => {
   const uniqueCategoryIds = Array.isArray(items) ? [...new Set(items.map(item => item.categoryId))] : []
   const uniqueItems = uniqueCategoryIds.map(id => items.find(el => el.categoryId === id))

   const titleItem = title ? <h2>{title}</h2> : null

   const showAllItem = showAll ? (
      <div className={ s.show_all }>
         <Link href={ showAll }>
            <a>
               {allGoodsText}
               <span className={ s.arrow }>
                  <Image
                     src={ '/images/common/arrow_right.svg' }
                     width={ 6 }
                     height={ 10 }
                     alt=""
                  />
               </span>
            </a>
         </Link>
      </div>
   ) : null

   return (
      <div className={ s.goods_group }>
         {titleItem}
         <div className={ s.goods_container }>
            <ItemsList items={ segmentation ? uniqueItems.slice(0, 15) : items } ItemLayout={ ProductItem } segmentation={ segmentation } />
         </div>
         {showAllItem}
      </div>
   )
}

export default GoodsGroup
